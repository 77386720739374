import React from 'react'
import { Head } from '@components'
import * as st from '@assets/styl/app.module.styl'
import { useLanguage } from '../hooks'
import { translatePath } from '../translations'
import cn from 'classnames'

export default () => {
  const { t } = useLanguage()

  return (
    <>
      <Head
        pathname={translatePath('/acesse-condominio/')}
        title={t('AcesseCondominio.titlePage')}
        description={t('AcesseCondominio.descriptionPage')}
      />

      <div className={st.core}>
        <section className={cn(st.hero, 'heroScroll')}>
          <div>
            <h1>
              <b>{t('AcesseCondominio.title.1')}</b>
              {''}
              {t('AcesseCondominio.title.2')}
              <br />
              <b>{t('AcesseCondominio.title.3')}</b>
              {t('AcesseCondominio.title.4')}
            </h1>
          </div>
          <div>
            <iframe
              src="https://www.youtube.com/embed/2guPmHlwp0s"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </section>

        <section className={st.download}>
          <div>
            <div className={st.appInfo}>
              <div></div>
              <div>
                <div></div>
                <div></div>
                <a
                  href="https://apps.apple.com/sr/app/dimas/id1600494665"
                  target="_blank"
                  className={st.ios}
                ></a>
                <a
                  href="https://play.google.com/store/apps/details?id=br.com.winker.wl.dimas&hl=pt_BR&gl=US"
                  target="_blank"
                  className={st.android}
                ></a>
              </div>
            </div>
          </div>
          <div></div>
        </section>
      </div>
    </>
  )
}
